// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 32, 32, 0.7);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  position: fixed;
}

css-doodle {
  position: fixed;
  z-index: -1;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,kCAA0B;UAA1B,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".App {\n  z-index: 1;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(32, 32, 32, 0.7);\n  backdrop-filter: blur(4px);\n  position: fixed;\n}\n\ncss-doodle {\n  position: fixed;\n  z-index: -1;\n}\n.App-header {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
