import './App.css';

function App() {
  return (
    <div className="App" style= {{ position: "absolete" }}>
        <header className="App-header">
            <span style={{ fontWeight: 600}}>
              Welcome to naumtsev.me
            </span>
            {LinksRow()}
            <div>
                {RandomImage()}
            </div>
        </header>
    </div>
  );
}

const LinksRow = () => {
    return (
        <div style={row_styles.container}>
            <span style={{ fontWeight: 200, fontSize: 25}}>Links:</span>
            <div style={row_styles.links}>
                <a href="https://t.me/naumtsev" style={row_styles.link}>
                    <img src="telegram-icon.svg" width="60vw" height="60vw" alt="telegram" />
                </a>
                <a href="https://github.com/naumtsev" style={row_styles.link}>
                    <img src="github-icon.svg" width="60vw" height="60vw" alt="github" />
                </a>
            </div>
        </div>
    );
};

const row_styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px', // Space between items
        flexWrap: 'nowrap', // Prevent wrapping to the next line
    },
    text: {
        fontSize: '20px',
        whiteSpace: 'nowrap', // Prevents breaking the "Links:" text
    },
    links: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px', // Space between icons
    },
    link: {
        display: 'inline-block',
    },
};



const RandomImage = () => {
    const imageSources = [
        "coders/content5.webp",
        "coders/content2.gif",
        "coders/content3.gif",
        "coders/content4.gif",
        "coders/content5.webp"
    ];

    const randomIndex = Math.floor(Math.random() * imageSources.length);

    return (
        <img
            src={imageSources[randomIndex]}
            style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px'}}
            alt="funny_image"
        />
    );

}

export default App;
