import React from 'react';
import ReactDOM from 'react-dom/client';
import 'css-doodle';
import './index.css';
import App from './App';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <css-doodle>
            <style>
                z-index: -1;
                @grid: 1 / 100vw 100vh / #0a0c27;
                background-size: 200px 200px;
                background-image: @doodle(
                  @grid: 6 / 100%;
                  @size: 4px;
                  font-size: 4px;
                  color: hsl(@r240, 30%, 50%);
                  box-shadow: @m3x5(
                    calc(4em - @nx*1em) @ny(*1em)
                      @p(@m3(currentColor), @m2(#0000)),
                    calc(2em + @nx*1em) @ny(*1em)
                      @lp
                  );
                );
            </style>
        </css-doodle>

        <App/>
    </React.StrictMode>
);

